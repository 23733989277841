import React from 'react';
import styled from 'styled-components';
import { Layout } from 'components';
import { graphql } from 'gatsby';
import moment from 'moment';
import { BlogCard, NewsletterSection, Tag } from 'components';
import BackgroundImage from 'gatsby-background-image';
import { uniq, flatten } from 'lodash';
import queryString from 'query-string';

import Robot from 'images/robot.png';

const StyledBlogHeader = styled.div`
  background: linear-gradient(
    84deg,
    rgba(66, 161, 219, 1) 28%,
    rgba(44, 117, 195, 1) 93%
  );
  background-position-x: 8%;

  .tag {
    font-family: 'Sansation Regular', sans-serif;
    letter-spacing: 1.36px;
    text-transform: uppercase;
    font-size: 15px;
    background: var(--blue-tag);
    margin-right: 9px;
    margin-bottom: 10px;
    padding: 4px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18), 0 1px 2px 0 rgba(0, 0, 0, 0.04),
      0 2px 6px 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 0 rgba(225, 225, 225, 0.5);
  }

  .uk-form-icon {
    color: var(--black);
    width: 65px;
  }

  .search-field,
  .uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
    padding-left: 55px !important;
  }

  .search-field,
  .search-field:focus {
    background: var(--white);
    color: var(--black);
  }

  .search-field::placeholder {
    color: var(--muted);
  }

  .robot-bg {
    background: url(${Robot}) left bottom no-repeat;
    height: 100%;
    background-position-x: 5%;
  }
`;

const BlogPage = ({
  data: {
    allPrismicBlogPost: { nodes: posts },
    prismicBlogPage: {
      data: {
        header_image,
        title,
        mailchimp_url,
        newsletter_heading,
        subscribe_button_text,
      },
    },
  },
  location: { search },
}) => {
  const [filter, setFilter] = React.useState(queryString.parse(search).filter);
  const tags = uniq(
    flatten(posts.map(({ data: { tags } }) => tags)).map((tags) => tags.tag)
  ).sort();

  React.useEffect(() => {
    setFilter(queryString.parse(search).filter || '');
  }, [search]);

  return (
    <Layout>
      <StyledBlogHeader className="jobs-search-header">
        <BackgroundImage fluid={header_image.localFile.childImageSharp.fluid}>
          <div
            className="uk-container robot-bg uk-container-medium uk-padding-large"
            uk-scrollspy="cls:uk-animation-fade"
          >
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-3@m uk-width-1-1 uk-text-left@m uk-text-center uk-margin-small-bottom uk-light">
                <h1>{title.text}</h1>
              </div>
              <div className="uk-width-expand@m uk-width-1-1">
                <div className="uk-flex uk-flex-wrap uk-flex-wrap-around uk-flex-left@m uk-flex-center uk-light">
                  {tags.length > 0 &&
                    tags.map((tag, i) => (
                      <Tag
                        style="alt"
                        key={i}
                        page="blog"
                        active={
                          filter &&
                          tag.toLowerCase().includes(filter.toLowerCase())
                        }
                      >
                        {tag}
                      </Tag>
                    ))}
                  <Tag style="alt" clear={true} page="blog">
                    ALL BLOGS
                  </Tag>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </StyledBlogHeader>
      <div className="uk-container uk-padding-large">
        <div
          className="uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-medium uk-grid-match "
          uk-grid="true"
          uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-medium; delay: 200; offset-top: -200;"
        >
          {posts.map(
            ({ uid: slug, data: { tags, title, date, author, image } }, i) => {
              switch (true) {
                case !filter:
                case tags.map((tags) => tags.tag).includes(filter):
                  return (
                    <BlogCard
                      key={i}
                      featuredimage={image.localFile}
                      date={moment(date).format('DD MMM YYYY')}
                      author={author}
                      to={slug}
                    >
                      {title.text}
                    </BlogCard>
                  );

                default:
                  return void 0;
              }
            }
          )}
        </div>
      </div>
      <NewsletterSection
        title={newsletter_heading.text}
        newsletter_url={mailchimp_url.url}
        button_text={subscribe_button_text}
      />
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query {
    prismicBlogPage {
      data {
        header_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title {
          text
        }
        subscribe_button_text
        mailchimp_url {
          url
        }
        newsletter_heading {
          text
        }
      }
    }

    allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
      nodes {
        uid
        data {
          title {
            text
          }
          date
          tags {
            tag
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          content {
            html
          }
          author
          author_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 512) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
